html {
    display: flex;
    min-height: 100%;
    font-size: 16px;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    background: #efefef;
}

body, #root {
    flex: 1;
    display: flex;
    flex-direction: column;
}

footer {
    margin-top: auto;
    padding: 20px;
    color: #666;
}

strong {
    // patch for firefox
    font-weight: bold;
}

#remoteAudios {
    display: none;
}
